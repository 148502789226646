import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import DownloadIcon from "../../assets/icons/download-files.png";

interface DownloadPDFInterface {
  params?: any;
  pdfLink?: any;
  dutchPdfLink?: any;
}

const DownloadPDF: FC<DownloadPDFInterface> = ({ pdfLink, dutchPdfLink }) => {
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className="container mx-auto px-4 lg:px-16 flex justify-center">
      <div className="w-full lg:w-[91%] lg:pr-[10px] lg:px-2">
        <a
          href={
            state?.language?.language === "fr"
              ? pdfLink?.pdf
              : dutchPdfLink?.pdf
          }
          download={
            state?.language?.language === "fr"
              ? pdfLink?.filename
              : dutchPdfLink?.filename
          }
          target="_blank"
          rel="noreferrer"
        >
          <div className="w-full bg-white border border-[#00A3DA] text-[#00A3DA] hover:bg-[#0590c1] hover:text-white border rounded-xl p-6 cursor-pointer">
            <div>
              <p className=" text-center text-[18px] font-semibold flex items-center justify-center">
                <span className="lg:hidden w-20 pr-2">
                  <img src={DownloadIcon} alt="" className="h-14" />
                </span>
                <u>{state?.language?.result?.downloadPDF}</u>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default DownloadPDF;
