import React, { FC, useContext, useEffect, useState } from "react";
// layout
import Layout from "../../components/layout";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useParams } from "react-router-dom";
import { now } from "jquery";
import Banner from "../../components/result/banner";
import HouseScale from "../../components/result/houseScale";
import DownloadPDF from "../../components/result/downloadPDF";
import ModifyInformation from "../../components/result/modifyInformation";
import BuildingDetails from "../../components/result/buildingDetails";
import Projects from "../../components/result/projects";
import ResultModal from "../../shared/modals/resultPageModal";
import {
  getVisitedUserData,
  visitedUserData,
  downloadPDF,
} from "../../context/actions/resultPage";
import { setModalHandler } from "../../context/actions/modal";
import NotificationMessage from "../../shared/notifications/successMessage";

interface ResultPageInterface {}

const ResultPage: FC<ResultPageInterface> = () => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const [loader, setLoader] = useState(true);
  // notification state
  const [notificationState, setNotificationState] = useState(false);
  // states for pdf content for dutch and french language
  const [pdfLink, setPdfLink]: any = useState();
  const [dutchPdfLink, setDutchPdfLink]: any = useState();
  // to get id from url
  let params = useParams();

  const GetPDFHandler = async () => {
    setLoader(true);
    const payload = {
      id: params?.id,
      lang: "fr",
    };
    const payload2 = {
      id: params?.id,
      lang: "de",
    };
    downloadPDF(payload).then((response) => {
      setPdfLink(response?.data);
    });
    downloadPDF(payload2).then((response) => {
      setDutchPdfLink(response?.data);
      setLoader(false);
    });
  };

  // useEffect to get UserVisted data and store into Global state
  useEffect(() => {
    let id = params?.id + "?" + now();
    getVisitedUserData(id)
      .then((response) => {
        if (response?.data?.data?.visitor_answer?.length === 0) {
          setModalHandler(
            {
              content: "",
              modal: "resultPage",
            },
            dispatch
          );
          setLoader(false);
        } else {
          visitedUserData(response?.data?.data, dispatch);
          GetPDFHandler();
        }
      })
      .catch(() => {
        setModalHandler(
          {
            content: "",
            modal: "resultPage",
          },
          dispatch
        );
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <Layout>
      <>
        {loader && (
          <div className="spinner-container">
            <div className="spinner">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="46" />
              </svg>
            </div>
          </div>
        )}
        <div>
          <NotificationMessage
            message={state?.language?.result?.succesMessageContent}
            show={notificationState}
            setState={setNotificationState}
          />
          <div>
            <ResultModal />
            <div className="bg-[#e6f4fb] pt-2 lg:pt-6">
              <Banner setNotificationState={setNotificationState} />
              <HouseScale />
              <DownloadPDF
                params={params}
                pdfLink={pdfLink}
                dutchPdfLink={dutchPdfLink}
              />
              <BuildingDetails />
              <ModifyInformation />
              <Projects />
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default ResultPage;
