import React, { FC, useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { useNavigate } from "react-router-dom";
import { storeStepValues } from "../../utils/forms";
import {
  setStepFormValues,
  addStepsFormData,
} from "../../context/actions/formsAction";
import { step4ValidationHandler } from "./formValidations";
import { storelocalStorageData } from "../../utils/forms";
import { smoothScroll } from "../../utils/smoothScroll";
// section 1 icons
import HouseQ3 from "../../assets/icons/questionsForm/step7/solar-panels.svg";
// Sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import InfoIcon from "../../assets/icons/information-button.svg";

interface Step7Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step3: FC<Step7Interface> = ({ setStep, setShowAnimation }) => {
  const navigate = useNavigate();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const [localStorageData, setLocalStorageData]: any = useState();
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name:
        state?.language?.language === "fr"
          ? "Panneaux photovoltaïques"
          : "Photovoltaik-Module",
      selected: "true",
    },
  ];
  const [step7, setStep7] = useState({
    question_two: {
      question_id: 34,
      question_title:
        "L'habitation possède-t-elle des panneaux photovoltaïques pour produire de l'électricité ?",
      answer_id: 0,
      answer_title: "",
    },
    question_three: {
      question_id: 35,
      question_title: "Quelle est la puissance crête installée ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_four: {
      question_id: 36,
      question_title: "Quel est le nombre de panneaux installés ?",
      answer_id: 0,
      answer_title: "",
    },
  });
  const [toggle, setToggle] = useState({
    // section 1
    sectioOneCheckBoxTwo: false,
  });

  const toggleHandler = (key: any, value: Boolean) => {
    let duplicate: any = { ...toggle };
    duplicate[key] = value;
    setToggle(duplicate);
  };
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);
  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData) {
      setLocalStorageData(formData);
      // to store in local and Global states

      setToggle(formData?.stepsevenToggle);
      setStepFormValues(formData?.stepsix, dispatch);
    }
    if (formData?.stepseven && Object.keys(formData?.stepseven).length > 0) {
      setStep7(formData?.stepseven);
    }
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // payload handler if data is not save then to get it from localStorage
  const payloadHandler = (step7: any, visitorData: any) => {
    const payload = {
      // age
      age: localStorageData?.homepageFormData?.age[0] + 1,
      // ownership_status
      ownership_status:
        state?.homePageForm !== undefined && state?.homePageForm?.button !== ""
          ? state?.homePageForm?.button === 1
            ? 6
            : state?.homePageForm?.button === 2
            ? 7
            : state?.homePageForm?.button === 3
            ? 8
            : 0
          : localStorageData?.homepageFormData?.button === 1
          ? 6
          : localStorageData?.homepageFormData?.button === 2
          ? 7
          : localStorageData?.homepageFormData?.button === 3
          ? 8
          : 0,
      // 5
      stepfive:
        state?.stepData?.stepfive !== undefined
          ? state?.stepData?.stepfive
          : localStorageData.stepfive,
      // 4
      stepfour:
        state?.stepData?.stepfour !== undefined
          ? state?.stepData?.stepfour
          : localStorageData.stepfour,
      // 1
      stepone:
        state?.stepData?.stepone !== undefined
          ? state?.stepData?.stepone
          : localStorageData.stepone,
      // 7
      stepseven: {
        ...step7,
        question_one:
          state?.stepData?.stepsix !== undefined
            ? state?.stepData?.stepsix?.question_two
            : localStorageData.stepsix?.question_two,
      },
      // 6
      stepsix: {
        question_one:
          state?.stepData?.stepsix !== undefined
            ? state?.stepData?.stepsix?.question_one
            : localStorageData.stepsix?.question_one,
      },
      // 3
      stepthree:
        state?.stepData?.stepthree !== undefined
          ? state?.stepData?.stepthree
          : localStorageData.stepthree,
      // 2
      steptwo:
        state?.stepData?.steptwo !== undefined
          ? state?.stepData?.steptwo
          : localStorageData?.steptwo,
      // typology_id
      typology_id:
        state?.stepData?.steptwo !== undefined
          ? state?.stepData?.steptwo?.selectedData?.typology_id
          : localStorageData?.steptwo?.selectedData?.typology_id,
      // typology_title
      typology_title:
        state?.stepData?.steptwo !== undefined
          ? state?.stepData?.steptwo?.question_one?.answer_id
          : localStorageData?.steptwo?.question_one?.answer_id,
      visitor_id: visitorData?.data?.id,
      // zipcode
      zipcode:
        state?.homePageForm !== undefined && state?.homePageForm?.address !== ""
          ? state?.homePageForm?.address
          : localStorageData?.homepageFormData?.address,
    };
    return payload;
  };

  // handler to store values into Global state
  const submitHandler = () => {
    setModalHandler(
      {
        content: "modal",
        modal: "formLoader",
      },
      dispatch
    );
    let validationOptions = step4ValidationHandler(step7, toggle, state);
    //
    if (validationOptions?.length > 0) {
      setModalHandler(
        {
          content: validationOptions,
          modal: "formError",
        },
        dispatch
      );
    } else {
      // to get data from locaStorage
      let localStorageData: any = localStorage.getItem("visitor");
      let visitorData = JSON.parse(localStorageData);
      //
      let stepseven = { stepseven: step7 };
      storelocalStorageData({ stepseven: step7, stepsevenToggle: toggle });
      // to save in global state
      setStepFormValues(stepseven, dispatch);
      // payload to send backend
      let payload = payloadHandler(step7, visitorData);
      // API call
      addStepsFormData(payload)
        .then(() => {
          setModalHandler(
            {
              content: "",
              modal: "",
            },
            dispatch
          );
          navigate(`/result/${visitorData?.data?.code}`);
        })
        .catch(() => {
          setModalHandler(
            {
              content: "",
              modal: "",
            },
            dispatch
          );
        });
    }
  };

  return (
    <div className="container mx-auto py-8 step1-container px-4">
      <div className="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start lg:flex-row">
        {/* left side */}
        <div className="w-12/12 lg:w-[70%] lg:overflow-y-auto lg:h-[54vh]">
          {/* 1st card */}
          <div className="w-full py-4 pl-4 pr-7 border-2 border-[#00A3DA] rounded-2xl mb-6">
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ3} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepSeven.questionTwo?.title} --- {`{"question_id": ${step7.question_two.question_id}, "answer_id": ${step7.question_two.answer_id}}`}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepSeven.questionTwo",
                      modal: "step7",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/* 1st section */}
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-center pt-6 lg:px-6">
                  <div className="py-3 px-1 lg:px-3 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step7?.question_two?.answer_id === 112
                          ? "bg-[#01a2da] text-[#fff] "
                          : "text-[#3A484D]"
                      }
                      `}
                      onClick={() =>
                        storeStepValues(
                          step7,
                          "question_two",
                          "",
                          112,
                          setStep7
                        )
                      }
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepSeven.questionOne?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 px-1 lg:px-3 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step7?.question_two?.answer_id === 113
                          ? "bg-[#01a2da] text-[#fff]"
                          : "text-[#3A484D]"
                      }
                      `}
                      onClick={() => {
                        storeStepValues(
                          step7,
                          "question_three",
                          "",
                          0,
                          setStep7
                        );
                        //
                        storeStepValues(
                          step7,
                          "question_four",
                          "",
                          0,
                          setStep7
                        );
                        //
                        storeStepValues(
                          step7,
                          "question_two",
                          "",
                          113,
                          setStep7
                        );
                        toggleHandler("sectioOneCheckBoxTwo", false);
                        smoothScroll("content2");
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepSeven.questionTwo?.answerTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {step7?.question_two?.answer_id === 112 && (
              <>
                {/* 2nd section */}
                <div>
                  <div className="flex items-center pt-12">
                    <h5 className="text-[#4A4A4A] font-semibold px-5 lg:px-9 text-[17px]">
                      {state?.language?.stepSeven.questionTwoSubOne?.title} --- {`{"question_id": ${step7.question_three.question_id}, "answer_id": ${step7.question_three.answer_id}}`}
                    </h5>
                  </div>
                  {/* content */}
                  <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-start lg:flex-row lg:items-center pt-4 px-6">
                    <div className="py-2 w-6/12 lg:w-[24%]">
                      <div
                        className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                    ${
                      !toggle?.sectioOneCheckBoxTwo &&
                      step7?.question_three?.answer_id === 114
                        ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                    ${
                      toggle?.sectioOneCheckBoxTwo &&
                      " bg-[#eee] cursor-not-allowed"
                    }
                    `}
                        onClick={() => {
                          if (!toggle?.sectioOneCheckBoxTwo) {
                            storeStepValues(
                              step7,
                              "question_three",
                              "",
                              114,
                              setStep7
                            );
                            smoothScroll("content2");
                          }
                        }}
                      >
                        <p className="text-[14px] text-center">
                          {
                            state?.language?.stepSeven.questionTwoSubOne
                              ?.answerOne
                          }
                        </p>
                      </div>
                    </div>
                    {/* line */}
                    <div
                      className={`hidden lg:block lg:w-[1%] h-1 
                                  ${
                                    !toggle?.sectioOneCheckBoxTwo &&
                                    (step7?.question_three?.answer_id === 115 ||
                                      step7?.question_three?.answer_id ===
                                        116 ||
                                      step7?.question_three?.answer_id === 117)
                                      ? "bg-[#01a2da]"
                                      : "bg-[#DFE1E2]"
                                  }
                `}
                    ></div>
                    <div className="py-2 w-6/12 lg:w-[24%]">
                      <div
                        className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    !toggle?.sectioOneCheckBoxTwo &&
                    step7?.question_three?.answer_id === 115
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  ${
                    toggle?.sectioOneCheckBoxTwo &&
                    " bg-[#eee] cursor-not-allowed"
                  }
                  `}
                        onClick={() => {
                          if (!toggle?.sectioOneCheckBoxTwo) {
                            storeStepValues(
                              step7,
                              "question_three",
                              "",
                              115,
                              setStep7
                            );
                            smoothScroll("content2");
                          }
                        }}
                      >
                        <p className="text-[14px] text-center my-1">
                          {
                            state?.language?.stepSeven.questionTwoSubOne
                              ?.answerTwo
                          }
                        </p>
                      </div>
                    </div>
                    {/* line */}
                    <div
                      className={`hidden lg:block lg:w-[1%] h-1
                     ${
                       !toggle?.sectioOneCheckBoxTwo &&
                       (step7?.question_three?.answer_id === 116 ||
                         step7?.question_three?.answer_id === 117)
                         ? "bg-[#01a2da]"
                         : "bg-[#DFE1E2]"
                     }
                                                  
                `}
                    ></div>
                    <div className="py-2 w-6/12 lg:w-[24%]">
                      <div
                        className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    !toggle?.sectioOneCheckBoxTwo &&
                    step7?.question_three?.answer_id === 116
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  ${
                    toggle?.sectioOneCheckBoxTwo &&
                    " bg-[#eee] cursor-not-allowed"
                  }
                  `}
                        onClick={() => {
                          if (!toggle?.sectioOneCheckBoxTwo) {
                            storeStepValues(
                              step7,
                              "question_three",
                              "",
                              116,
                              setStep7
                            );
                            smoothScroll("content2");
                          }
                        }}
                      >
                        <p className="text-[14px] text-center my-1">
                          {
                            state?.language?.stepSeven.questionTwoSubOne
                              ?.answerThree
                          }
                        </p>
                      </div>
                    </div>
                    {/* line */}
                    <div
                      className={`hidden lg:block lg:w-[1%] h-1
                                                  ${
                                                    !toggle?.sectioOneCheckBoxTwo &&
                                                    step7?.question_three
                                                      ?.answer_id === 117
                                                      ? "bg-[#01a2da]"
                                                      : "bg-[#DFE1E2]"
                                                  }
                `}
                    ></div>
                    <div className="py-2 w-6/12 lg:w-[24%]">
                      <div
                        className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    !toggle?.sectioOneCheckBoxTwo &&
                    step7?.question_three?.answer_id === 117
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  ${
                    toggle?.sectioOneCheckBoxTwo &&
                    " bg-[#eee] cursor-not-allowed"
                  }
                  `}
                        onClick={() => {
                          if (!toggle?.sectioOneCheckBoxTwo) {
                            storeStepValues(
                              step7,
                              "question_three",
                              "",
                              117,
                              setStep7
                            );
                            smoothScroll("content2");
                          }
                        }}
                      >
                        <p className="text-[14px] text-center my-1">
                          {
                            state?.language?.stepSeven.questionTwoSubOne
                              ?.answerFour
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pb-6 px-6">
                    <label className="radio-container mt-4">
                      <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                        {
                          state?.language?.stepSeven.questionTwoSubOne
                            ?.answerFive
                        }
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={toggle?.sectioOneCheckBoxTwo}
                        onChange={() => {
                          storeStepValues(
                            step7,
                            "question_four",
                            "",
                            0,
                            setStep7
                          );
                          storeStepValues(
                            step7,
                            "question_three",
                            "",
                            0,
                            setStep7
                          );
                          toggleHandler(
                            "sectioOneCheckBoxTwo",
                            toggle?.sectioOneCheckBoxTwo ? false : true
                          );
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                  </div>
                </div>
                {/* 3rd section */}
                {toggle?.sectioOneCheckBoxTwo && (
                  <div>
                    <div className="flex items-center">
                      <h5 className="text-[#4A4A4A] font-semibold px-5 lg:px-9 text-[17px]">
                        {state?.language?.stepSeven.questionTwoSubTwo?.title} --- {`{"question_id": ${step7.question_four.question_id}, "answer_id": ${step7.question_four.answer_id}}`}
                      </h5>
                    </div>
                    {/* content */}
                    <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start lg:justify-start lg:items-center pt-4 px-6">
                      <div className="py-2 w-6/12 lg:w-[24%]">
                        <div
                          className={`border rounded-2xl px-4 py-4 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                    ${
                      step7?.question_four?.answer_id === 119
                        ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                    `}
                          onClick={() => {
                            storeStepValues(
                              step7,
                              "question_four",
                              "",
                              119,
                              setStep7
                            );
                            smoothScroll("content2");
                          }}
                        >
                          <p className="text-[14px] text-center">
                            {
                              state?.language?.stepSeven.questionTwoSubTwo
                                ?.answerOne
                            }
                          </p>
                        </div>
                      </div>
                      {/* line */}
                      <div
                        className={`hidden lg:block lg:w-[1%] h-1
                  ${
                    step7?.question_four?.answer_id === 120 ||
                    step7?.question_four?.answer_id === 121 ||
                    step7?.question_four?.answer_id === 122
                      ? "bg-[#01a2da]"
                      : "bg-[#DFE1E2]"
                  }
                  `}
                      ></div>
                      <div className="py-2 w-6/12 lg:w-[24%]">
                        <div
                          className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                    ${
                      step7?.question_four?.answer_id === 120
                        ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                    `}
                          onClick={() => {
                            storeStepValues(
                              step7,
                              "question_four",
                              "",
                              120,
                              setStep7
                            );
                            smoothScroll("content2");
                          }}
                        >
                          <p className="text-[14px] text-center my-1">
                            {
                              state?.language?.stepSeven.questionTwoSubTwo
                                ?.answerTwo
                            }
                          </p>
                        </div>
                      </div>
                      {/* line */}
                      <div
                        className={`hidden lg:block lg:w-[1%] h-1
                                    ${
                                      step7?.question_four?.answer_id === 121 ||
                                      step7?.question_four?.answer_id === 122
                                        ? "bg-[#01a2da]"
                                        : "bg-[#DFE1E2]"
                                    }
                  `}
                      ></div>
                      <div className="py-2 w-6/12 lg:w-[24%]">
                        <div
                          className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                    ${
                      step7?.question_four?.answer_id === 121
                        ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                    `}
                          onClick={() => {
                            storeStepValues(
                              step7,
                              "question_four",
                              "",
                              121,
                              setStep7
                            );
                            smoothScroll("content2");
                          }}
                        >
                          <p className="text-[14px] text-center my-1">
                            {
                              state?.language?.stepSeven.questionTwoSubTwo
                                ?.answerThree
                            }
                          </p>
                        </div>
                      </div>
                      {/* line */}
                      <div
                        className={`hidden lg:block lg:w-[1%] h-1 
                                                      ${
                                                        step7?.question_four
                                                          ?.answer_id === 122
                                                          ? "bg-[#01a2da]"
                                                          : "bg-[#DFE1E2]"
                                                      }
                  `}
                      ></div>
                      <div className="py-2 w-6/12 lg:w-[24%]">
                        <div
                          className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                    ${
                      step7?.question_four?.answer_id === 122
                        ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                    `}
                          onClick={() => {
                            storeStepValues(
                              step7,
                              "question_four",
                              "",
                              122,
                              setStep7
                            );
                            smoothScroll("content2");
                          }}
                        >
                          <p className="text-[14px] text-center my-1">
                            {
                              state?.language?.stepSeven.questionTwoSubTwo
                                ?.answerFour
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* right side */}
        <div
          className="w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 lg:h-[54vh]"
          id="content2"
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item) => (
              <div className="flex justify-center" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 1 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "false"
                             ? "bg-[#E7F6FD]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div className="flex flex-col items-center lg:items-start justify-between w-full lg:w-8/12 px-2">
            <div className="w-7/12 lg:w-full lg:py-4 lg:px-2">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => {
                  setStep(6);
                  setShowAnimation("animate-right6");
                }}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="w-7/12 lg:w-full pt-3 lg:pt-0">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-lg py-5 text-white text-[15px] px-8"
                onClick={() => submitHandler()}
              >
                {state?.language?.result?.report}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
