import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { ScreenSize } from "../../utils/windowDimension";
import InfoIcon from "../../assets/icons/information-button.svg";
import CloseIcon from "../../assets/icons/close-grey.png";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import { setStepFormValues } from "../../context/actions/formsAction";
import { storelocalStorageData } from "../../utils/forms";
import HouseTypeDetailModal from "../../shared/modals/houseTypeDetailModal";
// houses types images
import IMG1 from "../../assets/images/forms/step2Houses/typologie_a.png";
import IMG2 from "../../assets/images/forms/step2Houses/typologie_b.png";
import IMG3 from "../../assets/images/forms/step2Houses/typologie_c.png";
import IMG4 from "../../assets/images/forms/step2Houses/typologie_d.png";
import IMG5 from "../../assets/images/forms/step2Houses/typologie_e.png";
import IMG6 from "../../assets/images/forms/step2Houses/typologie_f.png";
import IMG7 from "../../assets/images/forms/step2Houses/typologie_g.png";
import IMG8 from "../../assets/images/forms/step2Houses/typologie_h.png";
import IMG9 from "../../assets/images/forms/step2Houses/typologie_i.png";
import IMG10 from "../../assets/images/forms/step2Houses/typologie_j.png";
import IMG11 from "../../assets/images/forms/step2Houses/typologie_k.png";
import IMG12 from "../../assets/images/forms/step2Houses/typologie_l.png";
import IMG13 from "../../assets/images/forms/step2Houses/typologie_m.png";
import IMG14 from "../../assets/images/forms/step2Houses/typologie_n.png";
import IMG15 from "../../assets/images/forms/step2Houses/typologie_o.png";
import IMG16 from "../../assets/images/forms/step2Houses/typologie_p.png";
import IMG17 from "../../assets/images/forms/step2Houses/typologie_q.png";
import IMG18 from "../../assets/images/forms/step2Houses/typologie_r.png";
import IMG19 from "../../assets/images/forms/step2Houses/typologie_s.png";

interface Step2Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  setSelectedQuestion?: any;
  loader?: any;
}
// type for AllHousesType
type AllHousesType = {
  description: string;
  title: string;
  typology: string;
  typology_id: number;
};

const Step2: FC<Step2Interface> = ({
  setStep,
  setShowAnimation,
  setSelectedQuestion,
  loader,
}) => {
  // const screenSize = ScreenSize();
  // const [screenSize, setScreenSize] = useState(ScreenSize());

  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const [selectedHouseType, setSelectedHouseType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [houseDetail, setHouseDetail] = useState(false);
  // state to show detail part for selectedHouseDetail | selectedHouseModal
  const [selectedHouse, setSelectedHouse]: any = useState({
    houseDetails: {},
    selectedOption: "selectedHouseDetail",
  });
  const [houseModalData, setHouseModalData]: any = useState({});
  const [showAllTypes, setShowAllTypes]: any = useState("");
  // state for step2 questions
  const [stepTwo, setStepTwo]: any = useState();
  // all houses types
  const [allHouseTypes, setAllHouseTypes]: any = useState([]);
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);

  useEffect(() => {
    if (Object.keys(selectedHouse?.houseDetails).length > 0) {
      setStepTwo(selectedHouse?.houseDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHouse?.houseDetails]);

  // allHouseTypesHandler to get value and store into state
  const allHouseTypesHandler = (value: number | string) => {
    // to sort array on basis of typology e.g (a,b,c)
    let sortedHouseTypes = [...state?.allHousesTypes]?.sort(
      (a: any, b: any): any => {
        return a?.typology - b?.typology;
      }
    );
    if (value !== 2 && value === "selectedDefaultHouse") {
      // setSelectedHouse({
      //   houseDetails: state?.stepTwoSelectedHouse,
      //   selectedOption: "selectedDefaultHouse",
      // });
      setAllHouseTypes(sortedHouseTypes?.splice(0, 2));
    }
    // for responsive
    else if (window.screen.availWidth < 1024 && value === 0) {
      // setSelectedHouse({
      //   houseDetails: state?.stepTwoSelectedHouse,
      //   selectedOption: "selectedHouseDetail",
      // });
      setAllHouseTypes([]);
    }
    // condition to show 2 records
    else if (window.screen.availWidth > 1023 && value === 2) {
      // setSelectedHouse({
      //   houseDetails: state?.stepTwoSelectedHouse,
      //   // houseDetails: sortedHouseTypes[0],
      //   selectedOption: selectedHouse?.selectedOption
      //     ? selectedHouse?.selectedOption
      //     : "selectedHouseDetail",
      // });
      setAllHouseTypes(sortedHouseTypes?.splice(0, 2));
    }
    // condition to show all records after click on all typology button
    else {
      // setSelectedHouse({
      //   houseDetails: state?.stepTwoSelectedHouse,
      //   // houseDetails: sortedHouseTypes[0],
      //   selectedOption: selectedHouse?.selectedOption
      //     ? selectedHouse?.selectedOption
      //     : "selectedHouseDetail",
      // });
      setAllHouseTypes(sortedHouseTypes);
    }
  };

  // useEffect for secledHouse to show when user user submit 1st step data
  useEffect(() => {
    setSelectedHouse({
      houseDetails: state?.stepTwoSelectedHouse,
      selectedOption: "selectedHouseDetail",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.stepTwoSelectedHouse]);

  // useEffect for desktop
  useEffect(() => {
    if (window.screen.availWidth < 1024) {
      allHouseTypesHandler(0);
    } else {
      allHouseTypesHandler(2);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    // console.log("formData",formData)
    if (formData?.steptwo) {
      // to store in local and Global states
      setStepTwo(formData?.steptwo);
      setStepFormValues(formData?.steptwo, dispatch);
      setSelectedHouse({
        houseDetails: formData?.steptwo.selectedData,
        selectedOption: formData?.steptwo.selectedData.typology_id,
      });
    }
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const housesTypesImagesHandler = (name: string) => {
    switch (name) {
      case "a":
        return IMG1;
      case "b":
        return IMG2;
      case "c":
        return IMG3;
      case "d":
        return IMG4;
      case "e":
        return IMG5;
      case "f":
        return IMG6;
      case "g":
        return IMG7;
      case "h":
        return IMG8;
      case "i":
        return IMG9;
      case "j":
        return IMG10;
      case "k":
        return IMG11;
      case "l":
        return IMG12;
      case "m":
        return IMG13;
      case "n":
        return IMG14;
      case "o":
        return IMG15;
      case "p":
        return IMG16;
      case "q":
        return IMG17;
      case "r":
        return IMG18;
      case "s":
        return IMG19;
    }
  };

  // colors handler for modal details and selected details part
  const colorsHandler = () => {
    if (selectedHouse?.selectedOption === "selectedDefaultHouse") {
      return {
        mainContainerBGColor: "bg-[#FDFEFF]",
        detailBoxBorderColor: "border-[#00A3DA] opacity-[0.5]",
        backHouseTypeButtonBG: "bg-[#fff]",
        backHouseTypeButtonColor: "text-[#fff]",
        backHouseTypeButtonBorder: "border-[#ECF7FC]",
        houseTypeBoxBG: "bg-[#FDFEFF] opacity-[0.5]",
        houseTypeBoxBorder: "border-[#00A3DA]",
      };
    } else if (selectedHouse?.selectedOption === "selectedHouseModal") {
      return {
        mainContainerBGColor: "bg-[#D4EFF9]",
        detailBoxBorderColor: "border-[#fff]",
        backHouseTypeButtonBG: "bg-[#D4EFF9]",
        backHouseTypeButtonColor: "text-[#3A484D]",
        backHouseTypeButtonBorder: "border-[#BBE7F6]",
        houseTypeBoxBG: "bg-[#D4EFF9]",
        houseTypeBoxBorder: "border-[#BBE7F6]",
      };
    } else {
      return {
        mainContainerBGColor: "bg-[#fff]",
        detailBoxBorderColor: "border-[#00A3DA]",
        backHouseTypeButtonBG: "bg-[#fff]",
        backHouseTypeButtonColor: "text-[#315865]",
        backHouseTypeButtonBorder: "border-[#ECF7FC]",
        houseTypeBoxBG: "bg-[#fff]",
        houseTypeBoxBorder: "border-[#E2F4FB]",
      };
    }
  };

  // houseTypeModalHandler to store selceted house data and show on left box
  const houseTypeModalHandler = (houseType: AllHousesType, key: string) => {
    setSelectedHouse({
      houseDetails: houseType,
      selectedOption: key,
    });
  };

  // handler to store values into Global state
  const submitHandler = () => {
    if (Object.keys(stepTwo)?.length === 0 || stepTwo === undefined) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    } else {
      let steptwo = {
        steptwo: {
          selectedData: selectedHouse?.houseDetails,
          question_one: {
            answer_id: selectedHouse?.houseDetails.typology,
            answer_title: `typology_${selectedHouse?.houseDetails.typology}`,
            question_id: 9,
            question_title:
              "Votre type d’habitation est-elle conforme a l’image choisie pour vous? ",
          },
        },
        formStep: 3,
      };
      storelocalStorageData(steptwo);
      setStepFormValues(steptwo, dispatch);
      setStep(3);
      setShowAnimation("animate-left3");
      setSelectedQuestion(1);
    }
  };

  const selectedContentHandler = (typology: any) => {
    if (state?.language?.language === "fr") {
      const data = state?.allHousesTypes?.find(
        (item: any) => item.typology === typology
      );
      return data;
    } else {
      const data = state?.allHousesTypes?.find(
        (item: any) => item.typology === typology
      );
      return data;
    }
  };

  return (
    <>
      {/* detail modal for responsive */}
      <HouseTypeDetailModal
        houseModalData={houseModalData}
        houseTypeModalHandler={houseTypeModalHandler}
        stepTwo={stepTwo}
        setStepTwo={setStepTwo}
        selectedHouse={selectedHouse}
        housesTypesImagesHandler={housesTypesImagesHandler}
        setHouseModalData={setHouseModalData}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      {/* step 2 form */}
      <div
        className={`px-4 lg:px-0 relative ${
          colorsHandler()?.mainContainerBGColor
        } ${showModal ? "hidden" : "block"}`}
      >
        <div className="container mx-auto pt-10 pb-8 lg:px-12 step2-container flex justify-center">
          <div className="flex flex-col w-full lg:flex-row w-full">
            {/* left side selected detail of house*/}
            <div className="w-full lg:w-7/12 flex flex-col justify-between">
              {/* detail box and selected record box */}
              {showAllTypes === "" ||
              selectedHouse?.selectedOption === "selectedHouseDetail" ? (
                <div
                  className={`w-full rounded-2xl bg-white border-2
              ${colorsHandler()?.detailBoxBorderColor}
              `}
                >
                  {/* header */}
                  <div className="flex justify-between px-4 p-2 lg:p-5 ">
                    <div className="w-11/12">
                      <h4 className="text-[#555555] text-[18px] font-semibold pr-4">
                        {state?.language?.stepTwo?.questionOne?.title}   --- {`{"question_id": ${stepTwo?.question_one?.question_id || 9}, "answer_id": ${selectedHouse?.houseDetails.typology}}`}
                      </h4>
                    </div>
                    {selectedHouse?.selectedOption === "selectedHouseModal" ? (
                      <div
                        className="cursor-pointer pl-4 pt-2 w-1/12 justify-end"
                        onClick={() => {
                          if (houseDetail) {
                            setShowAllTypes("allRecords");
                            setHouseDetail(false);
                          } else {
                            houseTypeModalHandler(
                              selectedHouse?.houseDetails,
                              "selectedHouseDetail"
                            );
                            setHouseModalData({});
                          }
                        }}
                      >
                        <img src={CloseIcon} alt="" className="h-4" />
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer pt-2 w-1/12 flex justify-end"
                        onClick={() => {
                          setModalHandler(
                            {
                              content: "stepTwo.questionOne",
                              modal: "step1",
                            },
                            dispatch
                          );
                        }}
                      >
                        <img src={InfoIcon} alt="" className="h-5" />
                      </div>
                    )}
                  </div>
                  <>
                    {loader === "step2" ? (
                      <div className="loader_container flex justify-center py-12">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      // BODY
                      <div className="pt-6 pb-8 ">
                        <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start lg:justify-between">
                          {/* image */}
                          <div className="w-full lg:w-4/12 flex items-center justify-center lg:justify-start border-b border-[#E1F4FB] lg:border-0 p-2 lg:p-5 ">
                            <div>
                              {selectedHouse?.selectedOption ===
                              "selectedHouseModal" ? (
                                <img
                                  src={`${housesTypesImagesHandler(
                                    houseModalData?.typology
                                  )}`}
                                  alt=""
                                  className="mt-[-25px] lg:mt-[0px] h-[10rem] lg:h-60"
                                />
                              ) : (
                                <img
                                  src={`${housesTypesImagesHandler(
                                    selectedHouse?.houseDetails?.typology
                                  )}`}
                                  alt=""
                                  className="mt-[-25px] lg:mt-[0px] h-[10rem] lg:h-60 max-w-none	"
                                />
                              )}
                            </div>
                            <div className="block lg:hidden pl-2">
                              <h4 className="text-[#3A484D] text-[16px] font-bold">
                                {houseModalData !== undefined &&
                                Object.keys(houseModalData).length > 0
                                  ? houseModalData?.title
                                  : selectedHouse?.houseDetails?.title}
                              </h4>
                            </div>
                          </div>
                          {/* content */}
                          <div className="w-full lg:w-8/12 pl-5 pr-2 p-2 lg:p-5">
                            <h4 className="text-[#3A484D] text-[16px] font-bold lg:block hidden">
                              {houseModalData !== undefined &&
                              Object.keys(houseModalData).length > 0
                                ? selectedContentHandler(
                                    houseModalData?.typology
                                  )?.title
                                : selectedContentHandler(
                                    selectedHouse?.houseDetails?.typology
                                  )?.title}
                            </h4>
                            <p className="pt-4 text-[##3A484D] font-normal">
                              {houseModalData !== undefined &&
                              Object.keys(houseModalData).length > 0
                                ? selectedContentHandler(
                                    houseModalData?.typology
                                  )?.description
                                : selectedContentHandler(
                                    selectedHouse?.houseDetails?.typology
                                  )?.description}
                            </p>
                          </div>
                        </div>
                        {selectedHouse?.selectedOption ===
                          "selectedHouseModal" && (
                          <div className="flex justify-center pt-8">
                            <div>
                              <button
                                className="text-[#fff] bg-[#01a2da] hover:bg-[#0590c1] font-semibold text-[18px] text-center rounded-full py-2 px-4 lg:px-14"
                                onClick={() => {
                                  houseTypeModalHandler(
                                    houseModalData,
                                    "selectedHouseDetail"
                                  );
                                  setStepTwo(selectedHouse?.houseDetails);
                                }}
                              >
                                {state?.language?.stepTwo?.questionOne?.select}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>
              ) : (
                <div
                  className={`w-full rounded-2xl bg-white border-2 px-3 py-2 lg:h-[430px] overflow-auto
                  ${colorsHandler()?.detailBoxBorderColor}
                  `}
                >
                  <div
                    className="cursor-pointer flex  pl-4 w-full justify-end"
                    onClick={() => {
                      houseTypeModalHandler(
                        selectedHouse?.houseDetails,
                        "selectedHouseDetail"
                      );
                      setHouseModalData({});
                      setShowAllTypes("");
                    }}
                  >
                    <img src={CloseIcon} alt="" className="h-3" />
                  </div>
                  <div
                    className={`lg:px-0 lg:py-3 flex flex-wrap justify-between ${
                      window.screen.availWidth < 1024 && allHouseTypes?.length === 0
                        ? ""
                        : "py-1 px-1"
                    }`}
                  >
                    {state?.allHousesTypes?.map(
                      (houseType: AllHousesType, index: any) => (
                        <div
                          className={`cursor-pointer w-[49%] flex justify-between px-1 py-2 border-2 rounded-xl lg:rounded-2xl mt-3 lg:mt-0 lg:mb-3`}
                          key={index}
                        >
                          <div className="flex items-center px-2">
                            <div>
                              <img
                                src={`${housesTypesImagesHandler(
                                  houseType?.typology
                                )}`}
                                alt=""
                                className="h-28"
                              />
                            </div>
                          </div>
                          <div className="w-10/12 flex flex-col justify-between">
                            <div className="flex justify-between w-full items-center px-2">
                              <h4 className="text-[#555555] text-[14px] font-normal pr-2 w-10/12">
                                {houseType?.title}
                              </h4>
                              <div className="w-2/12 flex justify-end">
                                <img
                                  src={InfoIcon}
                                  alt=""
                                  className="h-5 cursor-pointer"
                                  onClick={() => {
                                    setSelectedHouse({
                                      ...selectedHouse,
                                      selectedOption: "selectedHouseModal",
                                    });
                                    setHouseDetail(true);
                                    setShowAllTypes("");
                                    setHouseModalData(houseType);
                                  }}
                                />
                              </div>
                            </div>
                            {/* button */}
                            <div className="flex lg:pt-3">
                              <button
                                className="text-white font-semibold text-[14px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-lg py-3 px-4"
                                onClick={() => {
                                  houseTypeModalHandler(
                                    houseType,
                                    "selectedHouseDetail"
                                  );
                                  setShowAllTypes("");
                                  setStepTwo(houseType);
                                }}
                              >
                                {state?.language?.stepTwo?.questionOne?.select}
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {/* button */}
              <div className="w-full flex justify-center pt-5">
                <button
                  className={`w-full font-semibold border-2 text-[18px] text-center rounded-2xl lg:rounded-full py-4 px-4 lg:px-12 ${
                    colorsHandler()?.backHouseTypeButtonBG
                  }
                ${colorsHandler()?.backHouseTypeButtonColor}
                ${
                  selectedHouse?.selectedOption === "selectedDefaultHouse" &&
                  "bg-[#0590c1] cursor-pointer"
                }
                ${
                  selectedHouse?.selectedOption === "selectedHouseDetail" &&
                  "hover:bg-[#01a2da] hover:text-[#fff] text-[#3A484D]"
                }
                ${
                  selectedHouse?.selectedOption === "selectedHouseDetail" &&
                  "cursor-pointer"
                }
                ${
                  selectedHouse?.selectedOption === "selectedHouseModal" &&
                  "cursor-default"
                }
                ${colorsHandler()?.backHouseTypeButtonBorder}`}
                  onClick={() => {
                    if (
                      window.screen.availWidth < 1024 &&
                      selectedHouse?.selectedOption !== "selectedDefaultHouse"
                    ) {
                      setSelectedHouse({
                        houseDetails: state?.stepTwoSelectedHouse,
                        selectedOption: "selectedDefaultHouse",
                      });
                      setAllHouseTypes([]);
                    } else if (
                      selectedHouse?.selectedOption === "selectedDefaultHouse"
                    ) {
                      setSelectedHouse({
                        houseDetails: selectedHouse?.houseDetails,
                        selectedOption: "selectedHouseDetail",
                      });
                    } else {
                      allHouseTypesHandler("selectedDefaultHouse");
                    }
                  }}
                >
                  {state?.language?.stepTwo?.questionOne?.defaultButtonText}
                </button>
              </div>
            </div>
            {/* right side */}
            <div className="w-full lg:w-5/12 flex flex-col justify-between pt-6 lg:pt-0 lg:px-4">
              <div
                className={`w-full rounded-2xl bg-white border-2 lg:py-5 lg:px-5 lg:h-[430px] lg:overflow-y-auto ${
                  colorsHandler()?.houseTypeBoxBG
                } ${colorsHandler()?.houseTypeBoxBorder}`}
              >
                {/* header */}
                <div className="hidden lg:block pb-2">
                  <h4 className="text-[#3A484D] text-[20px] font-bold px-2">
                    {state?.language?.stepTwo?.questionOne?.proposal}
                  </h4>
                </div>
                {/* body */}
                <div
                  className={`lg:px-0 lg:py-3 flex flex-col-reverse lg:flex-col justify-between ${
                    window.screen.availWidth < 1024 && allHouseTypes?.length === 0
                      ? ""
                      : "py-1 px-1"
                  }`}
                >
                  {allHouseTypes?.map(
                    (houseType: AllHousesType, index: any) => (
                      <div
                        className={`cursor-pointer flex justify-between px-2 py-2 border-2 rounded-xl lg:rounded-2xl mt-3 lg:mt-0 lg:mb-3
                    ${
                      selectedHouse?.selectedOption === "selectedHouseModal" &&
                      houseType?.typology === selectedHouseType
                        ? // selectedHouse?.houseDetails?.typology
                          "border-[#00A3DA]"
                        : "border-[#EBECED]"
                    }
                    `}
                        key={index}
                        onClick={() => {
                          setSelectedHouseType(houseType?.typology);
                          if (window.screen.availWidth < 1024) {
                            // setModalHandler(
                            //   {
                            //     content: "",
                            //     modal: "houseTypeModal",
                            //   },
                            //   dispatch
                            // );
                            setShowModal(true);
                            // setSelectedHouse({
                            //   ...selectedHouse,
                            //   selectedOption: "selectedHouseModal",
                            // });
                            setHouseModalData(houseType);
                            console.log("test2");
                          } else {
                            setSelectedHouse({
                              ...selectedHouse,
                              selectedOption: "selectedHouseModal",
                            });
                            console.log("test3");
                            setHouseModalData(houseType);
                            // setShowAllTypes("");
                          }
                        }}
                      >
                        <div className="flex items-center px-2">
                          <div>
                            <img
                              src={`${housesTypesImagesHandler(
                                houseType?.typology
                              )}`}
                              alt=""
                              className="h-24"
                            />
                          </div>
                        </div>
                        <div className="flex justify-between w-10/12 lg:w-8/12 items-center px-2">
                          <h4 className="text-[#555555] text-[16px] font-normal pr-2 w-10/12">
                            {selectedContentHandler(houseType?.typology).title} 
                          </h4>
                          <div className="w-2/12 flex justify-end">
                            <img
                              src={InfoIcon}
                              alt=""
                              className="h-5 cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {/* button */}
                  <div className="w-full flex justify-center lg:pt-3">
                    <button
                      className="text-white w-full font-semibold text-[16px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-xl lg:rounded-2xl py-4 px-4 lg:px-12"
                      onClick={() => {
                        // for responsive
                        if (window.screen.availWidth < 1024) {
                          allHouseTypes?.length !== 0
                            ? allHouseTypesHandler(0)
                            : allHouseTypesHandler("all");
                        }
                        // for desktop
                        else {
                          // allHouseTypes?.length > 2
                          //   ? allHouseTypesHandler(2)
                          //   : allHouseTypesHandler("all");
                          setSelectedHouse({
                            ...selectedHouse,
                            selectedOption: "selectedHouseModal",
                          });
                          setShowAllTypes("allRecords");
                          setHouseModalData({});
                        }
                      }}
                    >
                      {state?.language?.stepTwo?.questionOne?.allTypologiesLong}
                    </button>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className="flex flex-row lg:flex-col justify-between pt-4 lg:items-end px-2">
                <div className="lg:w-6/12 lg:py-4">
                  <button
                    className={`bg-white hover:bg-[#eee] border w-full text-[#315865] rounded-full py-[10px] text-[15px] px-10 
                ${colorsHandler()?.backHouseTypeButtonBG}
                ${
                  selectedHouse?.selectedOption === "selectedDefaultHouse" &&
                  "cursor-pointer hover:bg-[#eee]"
                }
                ${
                  selectedHouse?.selectedOption === "selectedHouseDetail" &&
                  "cursor-pointer"
                }
                ${
                  selectedHouse?.selectedOption === "selectedHouseModal" &&
                  "cursor-default"
                }
                ${colorsHandler()?.backHouseTypeButtonBorder}
                `}
                    onClick={() => {
                      setStep(1);
                      setShowAnimation("animate-right");
                    }}
                  >
                    {state?.language?.stepButton?.previous}
                  </button>
                </div>
                <div className="lg:w-6/12">
                  <button
                    className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                    onClick={() => submitHandler()}
                  >
                    {state?.language?.stepButton?.next}
                    <span className="absolute ml-[8px] lg:ml-[20px] mt-[6.5px]">
                      <img src={NextArrow} className="h-3" alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
